<template lang="pug">
transition(name="fade")
	SingaButton.scroll-to-top(v-if="showScrollButton && !excludedPath" @click="scrollToTop()" icon-right="arrow-up")
</template>

<script setup lang="ts">
const route = useRoute()
const showScrollButton = ref(false)
const pixelsToScrollBeforeButtonShows = 5000

const handleScroll = () => {
	showScrollButton.value = window.scrollY > pixelsToScrollBeforeButtonShows
}

const scrollToTop = () => {
	window.scrollTo({ top: 0, behavior: 'smooth' })
}

// Paths that should not have scroll to top button
const excludedPath = computed(() => {
	const excludedPaths = ['/karaoke', '/account', '/about', '/media', '/contact']
	return excludedPaths.some(path => route.fullPath.includes(path)) && !route.fullPath.includes('/results/') && !route.fullPath.includes('/language/')
})

const updateScrollListener = () => {
	if (!excludedPath.value) {
		window.addEventListener('scroll', handleScroll)
	} else {
		window.removeEventListener('scroll', handleScroll)
	}
}

watch(route, () => {
	updateScrollListener()
})

onMounted(() => {
	updateScrollListener()
})

onUnmounted(() => {
	window.removeEventListener('scroll', handleScroll)
})
</script>

<style scoped lang="sass">
.scroll-to-top
	position: fixed
	bottom: 100px
	right: 38px
	width: 56px
	height: 56px
	border-radius: 50%
	background-color: $color-grey-80
	color: $color-grey-30
	display: flex
	align-items: center
	justify-content: center
	border: none
	z-index: 100

.fade-enter-active
	transition: opacity .3s
.fade-leave-active
	transition: opacity .3s
</style>
