<template lang="pug">
.wrap
	LayoutPaymentFailed(v-if="user?.payment_failed")
	VitePwaManifest
	canvas.layout-canvas(ref="layoutCanvas" :style="{ background: linearBackground }")
	LayoutNav(v-if="settings && settings.content" :story="settings.content")

	.page
		slot(keep-alive)
		LayoutScrollToTopButton

	LayoutSingaFooter(v-if="settings && settings.content" :story="settings.content")
</template>

<script setup lang="ts">
import { storeToRefs } from 'pinia'
import { useQueueStore } from '~~/pinia/queueStore'
import { useUserStore } from '~~/pinia/userStore'

const userStore = useUserStore()
const { user } = storeToRefs(userStore)

const { locale } = useI18n()
const layoutCanvas = ref<HTMLCanvasElement | null>(null)
const customColor = ref('#12B961')

const setLang = (language: string) => {
	useHead({
		htmlAttrs: {
			lang: language
		}
	})
}
const { $preview } = useNuxtApp()
const version = $preview ? 'draft' : 'published'

// Fetch the settings
const { story: settings } = await useStoryblokFetch('settings', {
	version,
	resolve_links: 'url'
})
const linearBackground = computed(() => `linear-gradient(${customColor.value}, black)`)

watch(locale, (value) => {
	setLang(value)
})

onMounted(() => {
	setLang(locale.value)
	const queueStore = useQueueStore()
	queueStore.$hydrate({ runHooks: false })

	useEventOn('layoutGradient:applyColor', (color) => {
		customColor.value = color
	})

	useEventOn('layoutGradient:resetDefault', () => { customColor.value = '#12B961' })
})

onUnmounted(() => {
	useEventOff('layoutGradient:applyColor')
	useEventOff('layoutGradient:resetDefault')
})
</script>

<style lang="sass" scoped>
.layout-canvas
	background: linear-gradient(#052E18, black)
	height: 100svh
	width: 100%
	position: absolute
	opacity: 0.1
html
	background-color: black
.wrap
	min-height: 100vh
	display: flex
	flex-direction: column
	background-size: cover
	background-position: center
.page
	flex: 1
	position: relative
	min-height: 100vh
	overflow-x: hidden

.overflow-visible
	overflow-x: visible
.footer
	margin-top: auto
.relative-wrapper
	position: relative
</style>

<style lang="sass">
@media (prefers-color-scheme: dark)
	#sentry-feedback
		--background: #333333
		--accent-background: #17E87A
		--accent-foreground: black
</style>
